import { Box } from "@mui/material";

export default function PartnerIcon() {
  return (
    <Box
      sx={{
        height: 130,
        width: 130,
      }}
      alignSelf={"center"}
      mt={3}
      component="img"
      alt="partner-icon"
      src="/images/partner/partner.webp"
    />
  );
}
