import { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import CachedIcon from "@mui/icons-material/Cached";
import { Box, Button, Typography } from "@mui/material";
import { useSetRecoilState } from "recoil";

import {
  GET_ADMIN_SETTLEMENT_WITHDRAWAL_LIST_REQ_SEARCH_KIND,
  GET_ADMIN_SETTLEMENT_WITHDRAWAL_LIST_RES,
} from "@sellernote/_shared/src/api-interfaces/shipda-api/adminSettlement";
import { BANK_NAME_FILTER_OPTION_LIST } from "@sellernote/_shared/src/constants/forwarding/adminSettlement";
import ADMIN_SETTLEMENT_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_SETTLEMENT_QUERY";
import { FORWARDING_ADMIN_SETTLEMENT_ATOMS } from "@sellernote/_shared/src/states/forwarding/adminSettlement";
import { FORWARDING_INVOICE_ATOMS } from "@sellernote/_shared/src/states/forwarding/invoice";
import { Currency } from "@sellernote/_shared/src/types/common/common";
import {
  SettlementFinancialComment,
  SettlementFinancialWithdrawListData,
  WithdrawalStatus,
} from "@sellernote/_shared/src/types/forwarding/adminSettlement";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import useGetCSVDownloadInfoFromQuery from "@sellernote/_shared-for-admin/src/hooks/common/useGetCSVDownloadInfoFromQuery";
import useTableHeadFilter, {
  TableHeadFilterOption,
} from "@sellernote/_shared-for-admin/src/hooks/common/useTableHeadFilter";
import LegacyTable, {
  LegacyTableBodyRow,
  LegacyTableHeadCell,
} from "@sellernote/_shared-for-forwarding-admin/src/components/LegacyTable";
import useGetObjectWithTermSearchTypeKey from "@sellernote/_shared-for-forwarding-admin/src/hooks/useGetObjectWithTermSearchTypeKey";
import useSearchWithDate, {
  SearchWithDateTypeOption,
} from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSearchWithDate";
import useSearchWithTerm, {
  TermSearchType,
} from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSearchWithTerm";

import TableMemoModal from "pages/settlement/TableMemoModal";

import MatchWithdrawalBidIdModal from "./MatchWithdrawalBidIdModal";

type CellId = keyof SettlementFinancialWithdrawListData;

const termSearchTypeOptions: TermSearchType<GET_ADMIN_SETTLEMENT_WITHDRAWAL_LIST_REQ_SEARCH_KIND>[] =
  [
    {
      label: "수취명",
      value: "customerName",
    },
    {
      label: "출금액",
      value: "withdrawalAmount",
    },
  ];

const dateSearchTypeOptions: SearchWithDateTypeOption<"withdrawalDate">[] = [
  {
    label: "출금일",
    value: "withdrawalDate",
  },
];

const withdrawalStatusFilterOptions: TableHeadFilterOption<WithdrawalStatus>[] =
  [
    { label: "정상", value: "정상" },
    { label: "오류", value: "오류" },
  ];

function SettlementWithdrawalTable() {
  const setTrelloCardId = useSetRecoilState(
    FORWARDING_INVOICE_ATOMS.ADMIN_TRELLO_CARD_ID
  );

  const setRequestAmount = useSetRecoilState(
    FORWARDING_ADMIN_SETTLEMENT_ATOMS.WITHDRAWAL_REQUEST_AMOUNT
  );

  const setRequestInvoiceIds = useSetRecoilState(
    FORWARDING_ADMIN_SETTLEMENT_ATOMS.WITHDRAWAL_INVOICE_ID
  );

  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(25);
  const [showsMatchWithdrawalBidIdModal, setShowsMatchWithdrawalBidIdModal] =
    useState(false);
  const [partnerId, setPartnerId] = useState(0);
  const [paymentInvoiceId, setPaymentInvoiceId] = useState(0);
  const [withdrawalAmount, setWithdrawalAmount] = useState(0);
  const [showsTableMemoModal, setShowsTableMemoModal] = useState(false);
  const [withdrawalName, setWithdrawalName] = useState("");
  const [currency, setCurrency] = useState<Currency>("KRW");

  const { FilterPanel: BankNameFilterPanel, filter: bankNameFilterData } =
    useTableHeadFilter({
      filterOptions: BANK_NAME_FILTER_OPTION_LIST,
    });

  const {
    FilterPanel: WithdrawalStatusFilterPanel,
    filter: withdrawalStatusFilterData,
  } = useTableHeadFilter({
    filterOptions: withdrawalStatusFilterOptions,
  });

  const { debouncedSearchTerm, termSearchType, TermSearchPanel } =
    useSearchWithTerm({
      termSearchTypeOptions,
    });

  const { DateSearchPanel, dateSearchType, startDate, endDate } =
    useSearchWithDate({
      dateSearchTypeOptions,
    });

  const { objectWithTermSearchTypeKey } = useGetObjectWithTermSearchTypeKey({
    termSearchType,
    debouncedSearchTerm,
  });

  const {
    data: settlementWithdrawalData,
    refetch: refetchSettlementWithdrawalList,
  } = ADMIN_SETTLEMENT_QUERY.useGetAdminSettlementWithdrawalList({
    page: currentPage,
    perPage,
    isExcel: false,
    //쉽다 어드민 API에서는 단일값이므로 배열의 첫값을 사용
    bankName: bankNameFilterData ? bankNameFilterData[0] : undefined,
    withdrawalStatus: withdrawalStatusFilterData
      ? withdrawalStatusFilterData[0]
      : undefined,
    ...objectWithTermSearchTypeKey,
    ...(dateSearchType && startDate && endDate
      ? {
          startWithdrawalDate: new Date(startDate),
          endWithdrawalDate: new Date(endDate),
        }
      : {}),
  });

  const {
    isCSVDownloadRequested,
    setIsCSVDownloadRequested,
    dataForCSVDownload,
    ResponseHandlerOfFetchDataForCSVDownload,
    removeQueryOfFetchDataForCSVDownload,
    statusOfQueryOfFetchDataForCSVDownload,
  } = useGetCSVDownloadInfoFromQuery({
    query: ADMIN_SETTLEMENT_QUERY.useGetAdminSettlementWithdrawalExcelList,
    queryArgs: [
      {
        isExcel: true,
        //쉽다 어드민 API에서는 단일값이므로 배열의 첫값을 사용
        bankName: bankNameFilterData ? bankNameFilterData[0] : undefined,
        withdrawalStatus: withdrawalStatusFilterData
          ? withdrawalStatusFilterData[0]
          : undefined,
        ...objectWithTermSearchTypeKey,
        ...(dateSearchType && startDate && endDate
          ? {
              startWithdrawalDate: new Date(startDate),
              endWithdrawalDate: new Date(endDate),
            }
          : {}),
      },
    ],
  });

  const handleMatchWithdrawalBidIdModalOpen = useCallback(
    (
      settlementFinancialWithdrawalData: SettlementFinancialWithdrawListData
    ) => {
      return () => {
        setWithdrawalAmount(settlementFinancialWithdrawalData.withdrawalAmount);
        setPaymentInvoiceId(settlementFinancialWithdrawalData.id);
        setShowsMatchWithdrawalBidIdModal(true);
        setRequestInvoiceIds(settlementFinancialWithdrawalData.invoiceIds);
        setRequestAmount(settlementFinancialWithdrawalData.requestAmount);
        setPartnerId(settlementFinancialWithdrawalData.partnerCompanyId || 0);
      };
    },
    [setRequestAmount, setRequestInvoiceIds]
  );

  const handleTableMemoModalOpen = (
    settlementFinancialWithdrawalData: SettlementFinancialWithdrawListData
  ) => {
    return () => {
      setWithdrawalAmount(settlementFinancialWithdrawalData.withdrawalAmount);
      setPaymentInvoiceId(settlementFinancialWithdrawalData.id);
      setWithdrawalName(settlementFinancialWithdrawalData.customerName);
      setShowsTableMemoModal(true);
      setCurrency(settlementFinancialWithdrawalData.currency);
    };
  };

  const getMatchingBidIdButton = useCallback(
    (
      type: "register" | "edit",
      listData: SettlementFinancialWithdrawListData
    ) => {
      return (
        <Button
          onClick={handleMatchWithdrawalBidIdModalOpen(listData)}
          variant={type === "edit" ? "text" : "contained"}
          size="small"
        >
          {type === "edit" ? "수정" : "등록"}
        </Button>
      );
    },
    [handleMatchWithdrawalBidIdModalOpen]
  );

  const changeBidIdDataForTable = useCallback(
    (listData: SettlementFinancialWithdrawListData, isExcel: boolean) => {
      if (isExcel) {
        if (listData.bidIds.length > 0) {
          return listData.bidIds.toString();
        }
        return "-";
      }

      if (listData.bidIds.length > 0) {
        const bidIdButtons = listData.bidIds.map((bidId) => {
          return (
            <Button
              key={bidId}
              onClick={() => {
                setTrelloCardId(bidId);
                history.push(`/bid/detail/${bidId}`);
              }}
              variant="text"
              size="small"
            >
              {bidId}
            </Button>
          );
        });

        return (
          <>
            {bidIdButtons}

            {getMatchingBidIdButton("edit", listData)}
          </>
        );
      }

      return getMatchingBidIdButton("register", listData);
    },
    [getMatchingBidIdButton, history, setTrelloCardId]
  );

  const changeCommentDataForExcel = useCallback(
    (commentData: SettlementFinancialComment[] | null) => {
      if (!commentData) {
        return "-";
      }
      return commentData
        .map((commentItem) => {
          return Object.values(commentItem).toString();
        })
        .toString();
    },
    []
  );

  const mapDataForTable = useCallback(
    (
      data: GET_ADMIN_SETTLEMENT_WITHDRAWAL_LIST_RES | undefined,
      isExcel: boolean
    ) => {
      if (!data?.list) return [];

      return data?.list.map((v) => {
        const row: LegacyTableBodyRow<CellId> = {
          transactionDateTime: v.transactionDateTime,
          bankName: v.bankName,
          customerName: v.customerName,
          currency: v.currency,
          withdrawalAmount: `${toThousandUnitFormat(v.withdrawalAmount)}`,
          giroBankName: v.giroBankName,
          withdrawalStatus: v.withdrawalStatus,
          bidIds: changeBidIdDataForTable(v, isExcel),
          requestAmount: `${toThousandUnitFormat(v.requestAmount)}`,
          comment: isExcel ? (
            changeCommentDataForExcel(v.comment)
          ) : (
            <Button onClick={handleTableMemoModalOpen(v)}>
              {v.comment ? "확인" : "등록"}
            </Button>
          ),
        };
        return row;
      });
    },
    [changeBidIdDataForTable, changeCommentDataForExcel]
  );

  const headCells: LegacyTableHeadCell<CellId>[] = useMemo(() => {
    return [
      {
        id: "transactionDateTime",
        disablePadding: false,
        label: "일시",
        width: 100,
      },
      {
        id: "bankName",
        disablePadding: false,
        label: "수취은행",
        width: 150,
        filter: BankNameFilterPanel,
      },
      {
        id: "customerName",
        disablePadding: false,
        label: "수취자명",
        width: 100,
      },
      {
        id: "currency",
        disablePadding: false,
        label: "통화",
        width: 100,
      },
      {
        id: "withdrawalAmount",
        disablePadding: false,
        label: "출금액",
        numeric: true,
        width: 100,
      },
      {
        id: "giroBankName",
        disablePadding: false,
        label: "입금은행",
        width: 100,
      },
      {
        id: "withdrawalStatus",
        disablePadding: false,
        label: "처리상태",
        filter: WithdrawalStatusFilterPanel,
        width: 100,
      },
      {
        id: "bidIds",
        disablePadding: false,
        label: "의뢰번호",
        width: 100,
      },
      {
        id: "requestAmount",
        disablePadding: false,
        label: "출금요청 금액",
        numeric: true,
        width: 100,
      },
      {
        id: "comment",
        disablePadding: false,
        label: "메모",
        width: 100,
      },
    ];
  }, [BankNameFilterPanel, WithdrawalStatusFilterPanel]);

  const rows: LegacyTableBodyRow<CellId>[] = useMemo(() => {
    return mapDataForTable(settlementWithdrawalData, false);
  }, [settlementWithdrawalData, mapDataForTable]);

  const rowsForCSVDownload: LegacyTableBodyRow<CellId>[] = useMemo(() => {
    return mapDataForTable(dataForCSVDownload, true);
  }, [dataForCSVDownload, mapDataForTable]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          pt: 1,
          px: 1,
          background: "#fff",
        }}
      >
        <Box sx={{ display: "flex", gap: 1 }}>
          {TermSearchPanel}
          {DateSearchPanel}
        </Box>
        <Button
          variant="contained"
          endIcon={<CachedIcon />}
          key="dataRenewal"
          onClick={() => refetchSettlementWithdrawalList()}
          sx={{ height: "40px" }}
        >
          갱신
        </Button>
      </Box>

      <Box sx={{ p: 1, background: "#fff" }}>
        <LegacyTable
          headCells={headCells}
          rows={rows}
          toolbarItems={{
            left: [
              <Typography key="total">
                총 {settlementWithdrawalData?.total || 0}건
              </Typography>,
            ],
          }}
          csvDownloadInfo={{
            filename: `출금 리스트(${toFormattedDate(
              new Date(),
              "YYYY-MM-DD-HHmmss"
            )})`,
            scope: "all",
            isCSVDownloadRequested,
            setIsCSVDownloadRequested,
            rowsForCSVDownload,
            ResponseHandlerOfFetchDataForCSVDownload,
            statusOfQueryOfFetchDataForCSVDownload,
            removeQueryOfFetchDataForCSVDownload,
          }}
          pagination={{
            rowsPerPageOptions: [10, 25, 50, 100, 500, 1000, 10000],
            totalCount: settlementWithdrawalData?.total || 0,
            perPage,
            setPerPage,
            currentPage,
            setCurrentPage,
          }}
        />
      </Box>

      {showsMatchWithdrawalBidIdModal && (
        <MatchWithdrawalBidIdModal
          showsMatchWithdrawalBidIdModal={showsMatchWithdrawalBidIdModal}
          setShowsMatchWithdrawalBidIdModal={setShowsMatchWithdrawalBidIdModal}
          partnerId={partnerId}
          paymentInvoiceId={paymentInvoiceId}
          setPartnerId={setPartnerId}
          withdrawalAmount={withdrawalAmount}
          refetchSettlementWithdrawalList={refetchSettlementWithdrawalList}
        />
      )}

      {showsTableMemoModal && (
        <TableMemoModal
          showsTableMemoModal={showsTableMemoModal}
          setShowsTableMemoModal={setShowsTableMemoModal}
          name={withdrawalName}
          paymentInvoiceId={paymentInvoiceId}
          amount={withdrawalAmount}
          refetchListData={refetchSettlementWithdrawalList}
          settlementWithdrawalData={settlementWithdrawalData?.list}
          type="withdrawal"
          currency={currency}
        />
      )}
    </Box>
  );
}
export default SettlementWithdrawalTable;
