/**
 * 숫자 배열을 요일로 바꾸어주는 함수
 */
export const dayCheck = (value?: number[]) => {
  if (!value) {
    return "";
  }
  const stringDay = [];
  if (value[0] === 1) {
    stringDay[0] = "월";
  }
  if (value[1] === 1) {
    stringDay[1] = "화";
  }
  if (value[2] === 1) {
    stringDay[2] = "수";
  }
  if (value[3] === 1) {
    stringDay[3] = "목";
  }
  if (value[4] === 1) {
    stringDay[4] = "금";
  }
  if (value[5] === 1) {
    stringDay[4] = "토";
  }
  if (value[6] === 1) {
    stringDay[4] = "일";
  }
  return stringDay;
};

/**
 * 숫자 천단위마다 , 찍어주는 함수
 */
export const numberFormat = (inputNumber: any) => {
  if (inputNumber === null || inputNumber === undefined) {
    return 0;
  }
  return inputNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
