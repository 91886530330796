import { useState } from "react";
import { useQueryClient } from "react-query";
import { useAtom } from "jotai";

import { CREATE_PARTNER_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/partnerManagement";
import ADMIN_PARTNER_MANAGEMENT_QUERY, {
  ADMIN_PARTNER_MANAGEMENT_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/ADMIN_PARTNER_MANAGEMENT_QUERY";
import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";
import { FORWARDING_PARTNER_MANAGEMENT_ATOMS } from "@sellernote/_shared-for-forwarding-admin/src/jotaiStates/partnerManagement";

import { setPayload, validateCreatePartner } from "../utils";

export default function useHandleCreatePartner({
  onModalClose,
  timeStamp,
  handleFileListReset,
  validateFile,
}: {
  onModalClose: () => void;
  timeStamp: number;
  handleFileListReset: () => void;
  validateFile: () => boolean;
}) {
  const [{ page, perPage }, setParams] = useAtom(
    FORWARDING_PARTNER_MANAGEMENT_ATOMS.SEARCH_PARAMS
  );

  const { handleSnackbarOpen } = useSnackbar();

  const queryClient = useQueryClient();

  const [isSelected, setIsSelected] = useState(false);
  const [isCreate, setIsCreate] = useState(false);

  const handleSuccess = () => {
    handleSnackbarOpen("파트너를 성공적으로 생성했습니다.");
    setParams((prev) => ({ ...prev, page: 0 }));
    handleFileListReset();
    setIsCreate(false);
    setIsCreate(false);
    onModalClose();

    return queryClient.invalidateQueries(
      ADMIN_PARTNER_MANAGEMENT_QUERY_KEY_GEN.getPartnerList({ page, perPage })
    );
  };

  const { mutate: createPartner } =
    ADMIN_PARTNER_MANAGEMENT_QUERY.useCreatePartner(handleSuccess);

  const [form, setForm] = useState<CREATE_PARTNER_REQ>({
    name: "",
    businessArea: "",
    countries: [],
    language: "",
    transportModes: [],
    invoiceIssueFlag: true,
    managerName: "",
    managerPhone: "",
    managerPosition: "",
    managerEmail: "",
    targetAccountPayloads: [{ BRN: "", fileRefs: null }],
  });

  const handlePartnerCreate = () => {
    if (!validateCreatePartner(form, handleSnackbarOpen)) return;

    /** 거래처 선택이 아닌 경우 파일 첨부 여부 확인 */
    if (isCreate && !validateFile()) {
      handleSnackbarOpen("거래처 등록 필수 파일을 첨부해주세요.", "warning");
      return;
    }

    const payload = setPayload({ form, isSelected, timeStamp });

    createPartner(payload);
  };

  /** 파트너 생성 > 정보 업데이트  */
  function handleInfoUpdate<T>(name: string, value: T | T[]) {
    setForm((prev) => ({ ...prev, [name]: value }));
  }

  /** 파트너 생성 > 거래처 정보 > 사업자 등록번호 업데이트 */
  const handleAutoCompleteSelect = (val: string | null, isNew?: boolean) => {
    if (val === null) {
      handleSnackbarOpen(
        "정상적인 사업자등록번호가 아닙니다. 해당 거래처 정보를 확인해주세요.",
        "warning"
      );
      return;
    }

    if (isNew) {
      setIsSelected(false);
      setIsCreate(true);
    }

    setForm({
      ...form,
      targetAccountPayloads: [
        {
          BRN: val.replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3"),
          fileRefs: null,
        },
      ],
    });
  };

  return {
    handlePartnerCreate,

    form,
    handleInfoUpdate,

    isSelected,
    setIsSelected,

    isCreate,
    setIsCreate,

    handleAutoCompleteSelect,
  };
}
