import { Dispatch, SetStateAction } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import {
  GET_PARTNER_DETAIL_RES,
  UPDATE_PARTNER_REQ,
} from "@sellernote/_shared/src/api-interfaces/shipda-api/partnerManagement";
import ADMIN_PARTNER_MANAGEMENT_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_PARTNER_MANAGEMENT_QUERY";
import { AdminBidTransportMode } from "@sellernote/_shared/src/types/forwarding/adminBid";
import {
  PartnerBusinessArea,
  SelectedCountry,
} from "@sellernote/_shared/src/types/forwarding/partnerManagement";
import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";

import CountryInCharge from "pages/partner-management/components/CountryInCharge";
import PartnerType from "pages/partner-management/components/PartnerType";
import TransportMode from "pages/partner-management/components/TransportMode";
import NO_LABEL from "pages/partner-management/styles";
import BOX_STYLE from "../styles";

import PartnerIcon from "./PartnerIcon";

export default function PartnerInfo({
  data,
  refetchDetail,
  partnerInfo,
  setPartnerInfo,
}: {
  data: GET_PARTNER_DETAIL_RES;
  refetchDetail: () => void;
  partnerInfo: Partial<UPDATE_PARTNER_REQ>;
  setPartnerInfo: Dispatch<SetStateAction<Partial<UPDATE_PARTNER_REQ>>>;
}) {
  const { id }: { id: string } = useParams();

  const { handleSnackbarOpen } = useSnackbar();

  const handleSuccess = () => {
    handleSnackbarOpen("파트너 정보가 수정되었습니다.");
    refetchDetail();
  };

  const { mutate: updatePartner } =
    ADMIN_PARTNER_MANAGEMENT_QUERY.useUpdatePartner(Number(id), handleSuccess);

  const {
    name,
    businessArea,
    countries,
    language,
    transportModes,
    invoiceIssueFlag,
  } = data;

  const defaultTransportModeList = (() => {
    return transportModes.map((freight) => ({
      label: freight,
      value: freight,
    }));
  })();

  const handlePartnerUpdate = () => {
    updatePartner(partnerInfo);
  };

  return (
    <Box sx={BOX_STYLE}>
      <Box display={"flex"} flexDirection={"column"} gap={2}>
        <PartnerIcon />

        <Box
          alignSelf="center"
          display="flex"
          flexDirection={"column"}
          textAlign={"center"}
        >
          <Input
            sx={{ ...NO_LABEL, mt: 1 }}
            inputProps={{ style: { textAlign: "center" } }}
            placeholder="파트너명"
            defaultValue={name}
            onChange={() => setPartnerInfo({ ...partnerInfo, name })}
          />

          <Typography sx={{ mt: 2, color: "#858585" }} variant="caption">
            쉽다 비즈니스 파트너
          </Typography>
        </Box>

        <Box mt={3}>
          <InputLabel>파트너 유형</InputLabel>

          <PartnerType
            defaultValue={businessArea}
            handleTypeUpdate={(type: PartnerBusinessArea) => {
              setPartnerInfo({ ...partnerInfo, businessArea: type });
            }}
          />
        </Box>

        <Box>
          <InputLabel>담당 국가</InputLabel>

          <CountryInCharge
            defaultList={countries}
            countryList={[]}
            setCountryList={(list: SelectedCountry[]) => {
              const countryList = list.map((country) => country.value);
              setPartnerInfo({ ...partnerInfo, countries: countryList });
            }}
            hidesLabel
            sx={{ minWidth: 250 }}
          />
        </Box>

        <Box>
          <InputLabel>언어</InputLabel>

          <Select
            defaultValue={language}
            onChange={({ target: { value } }) => {
              setPartnerInfo({ ...partnerInfo, language: value });
            }}
            sx={{ ...NO_LABEL, width: "100%" }}
            size="small"
          >
            <MenuItem value={"kr"}>한국어</MenuItem>
            <MenuItem value={"en"}>영어</MenuItem>
          </Select>
        </Box>

        <Box>
          <InputLabel>운송 유형</InputLabel>

          <TransportMode
            defaultList={defaultTransportModeList}
            setTransportModeList={(list) => {
              setPartnerInfo({
                ...partnerInfo,
                transportModes: list.map(
                  ({ value }) => value as AdminBidTransportMode
                ),
              });
            }}
            hidesLabel
            sx={{ minWidth: 250 }}
          />
        </Box>

        <Box>
          <FormControlLabel
            sx={{ m: 0 }}
            control={
              <Checkbox
                onChange={(e) =>
                  setPartnerInfo({
                    ...partnerInfo,
                    invoiceIssueFlag: e.target.checked,
                  })
                }
                defaultChecked={invoiceIssueFlag}
              />
            }
            label="계산서 발행"
            labelPlacement="start"
          />
        </Box>

        <Divider />

        <Box mt={1} onClick={handlePartnerUpdate}>
          <Button variant="contained" fullWidth size="small">
            저장
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
